<template>
    <div>
        <app-loader :value="$apollo.loading"/>
        <login-screen @complete="$emit('login')"></login-screen>
    </div>
</template>


<script>
    import { user } from '@/graphql'
    export default {
        apollo: {
            user
        },
        watch: {
            user (value) {
                if (value) {
                    this.$router.push('/')
                }
            }
        }
    }
</script>
